<template>
<div style="width: 100%;overflow-x: auto;">
    <iframe style="display: block; width: 1616px; height: 1191px; margin: 0px; padding: 0px; border: none;" src="//public.tableau.com/views/2_15662686214720/1?:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fpublic.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=yes&:animate_transition=yes&:display_static_image=no&:display_spinner=no&:display_overlay=yes&:display_count=yes&:loadOrderID=0" frameborder="0"></iframe>
</div>
</template>
<script>
export default {
    name: 'ns',
    created () {
        // console.log('ns')
    },
    mounted () {
                //   var divElement = document.getElementById('viz1566269609452'); var vizElement = divElement.getElementsByTagName('object')[0]; vizElement.style.width = '1616px'; vizElement.style.height = '1191px'; var scriptElement = document.createElement('script'); scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js'; vizElement.parentNode.insertBefore(scriptElement, vizElement);              

    }
}
</script>